<template>
  <div></div>
</template>
<script>
export default {
  name: 'redirect',
  data() {
    return {
      redirectInfo: {
        '/report/raymonddouglaschong': 'https://www.mychinaroots.com/services/family-websites/zhang-odyssey',
        '/report/raymonddouglaschong/ancestors':
          'https://www.mychinaroots.com/services/family-websites/zhang-odyssey/ancestors.html',
      },
    };
  },
  created() {
    if (this.redirectInfo[this.$route.path]) {
      window.location.href = this.redirectInfo[this.$route.path];
    }
  },
};
</script>
